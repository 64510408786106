@import '../../styles/customMediaQueries.css';

.root {}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.mainWrapper {
  background-color: #fff;
  /* padding: 61px 85px; */
}

.mainContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 1581px;
  width: 100%;
  padding: 60px 0;

  @media(max-width: 1700px) {
    padding: 60px;
  }

  @media(max-width: 1160px) {
    padding: 30px;
  }

  @media(max-width: 768px) {
    padding: 24px;
  }
}

.noSpace {
  margin-left: auto;
  margin-right: auto;
  max-width: 1581px;
  width: 100%;
  padding: 0 0 60px 0;

  @media(max-width: 1700px) {
    padding: 60px;
  }

  @media(max-width: 1160px) {
    padding: 30px;
  }

  @media(max-width: 768px) {
    padding: 24px;
  }
}

.tabsSelected {
  color: #4661ED;
  font-weight: 700;
}

.tabsText {
  color: #595858;
}

.homeTabs {
  & a {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;
  }

  & .active {
    background-color: red;
  }

  &>div {
    margin-left: 0;
    margin-right: 35px;

    & a {
      width: auto;
      margin-bottom: 0;

      & span {
        padding: 0;
      }
    }
  }


}

.activeLink>span {
  font-weight: 700 !important;
  color: #1fd1bc !important;
}

.categoriesTabs {
  margin-bottom: 31px;

  & .homeTabs {
    flex-wrap: wrap;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.secondSection {
  margin-top: 69px;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  /* composes: sectionContent;
  margin-top: 3vh; */
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.lineDivider {
  margin: 43px 0;
}

.noLoginSection {
  padding-top: 70px;
}

.catSectionHeading {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #21201F;
}

.seeMoreLink {
  margin-bottom: 70px;
  text-align: right;

  @media(max-width: 1024px) {
    margin-bottom: 40px;
  }

  & a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.15em;
    color: #1419A4;
  }
}

.venSectionHeading {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #21201F;
  /* margin-top: 85px; */
  margin-bottom: 94px;

  @media(max-width: 1024px) {
    margin-bottom: 30px;
  }
}

.seeMore {
  margin-bottom: 50px;
  text-align: right;

  & a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.15em;
    color: #1419A4;
  }
}

.learnVendor {
  padding-top: 70px;
  padding-bottom: 90px;

  & .vendorSectionHeading {
    font-family: 'Martel Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #21201F;
    margin-bottom: 89px;

    @media(max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  & .vendorGrid {
    display: flex;
    gap: 62px;

    @media(max-width: 600px) {
      flex-wrap: wrap;
    }

    & .vendorDescription {
      width: 50%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & .longDescription {
        font-family: 'Martel';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.18px;
        text-transform: capitalize;
        color: #000000;

        @media(max-width: 1024px) {
          margin-bottom: 15px;
        }
      }

      & .bottomButton {
        text-align: center;

        & button {
          background: #FFB169;
          border: 1px solid #FFFFFF;
          border-radius: 100px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 38px;
          text-align: center;
          letter-spacing: 0.15em;
          color: #000000;
          padding: 7px 20px;
          height: 51px;
          width: 296px;
        }
      }
    }

    & .vendorImage {
      & img {
        width: 100%;
      }
    }
  }
}

.bottomButtonSubmit {
  text-align: center;
  margin-top: 34px;

  & button {
    background: #FFB169;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #000000;
    padding: 7px 20px;
    height: 51px;
    width: 296px;
  }
}

.titleTop {
  display: flex;
  margin-bottom: 43px;
  align-items: center;
  justify-content: space-between;

  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #000000;
    font-family: 'Avenir LT Std', sans-serif;
    margin: 0;

    @media(max-width: 768px) {
      font-size: 18px;
    }
  }

  & .seeAll {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ED;
    font-family: 'Avenir LT Std', sans-serif;

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
}

.vendorEventSize {
  padding-bottom: 85px;
}

.coverBox {
  & img {
    width: 100%;
  }
}

.shortDescription {
  margin-top: 90px;
  font-family: 'Martel';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  color: #000000;

  @media(max-width: 1024px) {
    margin-top: 30px;
  }
}